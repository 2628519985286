import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { makeStyles, Container, Box, Typography, Grid } from '@material-ui/core';

import MainLayout from 'layouts/MainLayout';
import LogoImage from '/public/home/teshigpng.png';

import HeadImage1 from '/public/home/home_bg_1.jpeg';
import HeadImage2 from '/public/home/home_bg_2.jpeg';
import HeadImage3 from '/public/home/home_bg_3.jpeg';

import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import MapRoundedIcon from '@material-ui/icons/MapRounded';

// actions
import { getPro } from 'context/actions/programs';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#373A46',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background 0.4s linear',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    box: {
        width: '100%',
        height: 100,
        borderRadius: 6,
        display: 'grid',
        backgroundColor: '#1e93f0',
        alignContent: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#1777c4',
        },
    },
    text: {
        color: 'white',
        textAlign: 'center',
        lineHeight: 1.2,
        padding: '5px 18px',
    },
    icon: {
        color: 'white',
    },
    title: {
        padding: '20px 40px',
        justifyItems: 'center',
        backgroundColor: 'red',
        display: 'grid',
        alignContent: 'center',
        borderRadius: 6,
        width: 'fit-content',
    },
}));

const IndexPage = ({ list }) => {
    const classes = useStyles();

    const [position, setPosition] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (position === 2) setPosition(0);
            else setPosition(position + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, [position]);

    const choosedImage = [HeadImage1, HeadImage2, HeadImage3][position];

    return (
        <Container
            container
            maxWidth={false}
            className={classes.root}
            style={{ backgroundImage: `url(${choosedImage.src})` }}
        >
            <Box
                style={{
                    border: '3px solid #ffffff17',
                    backdropFilter: 'blur(5px)',
                    padding: '70px 110px',
                    borderRadius: ' 10px',
                }}
            >
                <Box style={{ textAlign: 'center' }}>
                    <Image src={LogoImage} width={170} height={170} alt="logo image" />
                    <Typography style={{ color: 'white', fontSize: 30, fontWeight: 'bold' }}>
                        БУЛГАН АЙМГИЙН
                    </Typography>
                    <Typography
                        style={{
                            color: '#ffd102',
                            fontSize: 60,
                            fontWeight: 'bold',
                            marginTop: -12,
                        }}
                    >
                        ТЭШИГ СУМ
                    </Typography>
                </Box>
                <Box className={classes.content}>
                    <Box style={{ marginTop: 20 }}>
                        <Container maxWidth="md">
                            <Grid container spacing={3}>
                                <Grid lg={4} md={4} sm={12} xs={12} key={0} item>
                                    <Link href="/home" passHref>
                                        <Box className={classes.box}>
                                            <MapRoundedIcon className={classes.icon} />
                                            <Typography
                                                className={classes.text}
                                                variant="subtitle1"
                                                style={{ width: 100 }}
                                            >
                                                Үндсэн cайт
                                            </Typography>
                                        </Box>
                                    </Link>
                                </Grid>
                                {list.map((item, i) => {
                                    return (
                                        <Grid lg={4} md={4} sm={12} xs={12} key={item.id} item>
                                            <Link href={item.link} passHref>
                                                <a target="_blank" passHref>
                                                    <Box className={classes.box}>
                                                        {i === 0 ? (
                                                            <AssignmentRoundedIcon
                                                                className={classes.icon}
                                                            />
                                                        ) : (
                                                            <CheckBoxRoundedIcon
                                                                className={classes.icon}
                                                            />
                                                        )}
                                                        <Typography
                                                            className={classes.text}
                                                            variant="subtitle1"
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </Box>
                                                </a>
                                            </Link>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

IndexPage.Layout = MainLayout;

export async function getServerSideProps() {
    const list = await getPro();

    return {
        props: { list }, // will be passed to the page component as props
    };
}

export default IndexPage;
