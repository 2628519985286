import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: theme.palette.background.default,
    },
}));

export default function MainLayout({ children }) {
    const classes = useStyles();

    return (
        <main>
            <div className={classes.root}>{children}</div>
        </main>
    );
}
